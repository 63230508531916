const selects = document.querySelectorAll('.btn-group.bootstrap-select.mb-15');
const inputs = document.querySelectorAll('.input-block-level.form-control');
inputs.forEach(inp => {
    inp.value = '';
    filterList(inp);
})
if (selects) selects.forEach(el => {
    el.classList.remove('open');
    let elems = el.querySelectorAll('.dropdown-menu li');
    el.querySelector('.btn.dropdown-toggle.selectpicker .filter-option.pull-left').textContent = elems[
        0].querySelector('span .text').textContent;
    elems.forEach(e => e.classList.remove('selected', 'active'));
    elems[0].classList.add('selected', 'active');
});
//Добавляем классы  'selected' и 'active' по нажатию на элемент списка, текст на кнопку и закрываем селект
function setActiveClass(li) {
    li.getAttribute("data-type");
    const ul = li.closest('ul');
    const select = ul.parentElement.parentElement;
    const btn = select.querySelector('.btn.dropdown-toggle.selectpicker .filter-option.pull-left');
    const elems = ul.querySelectorAll('li');
    elems.forEach(el => el.classList.remove('selected', 'active'));
    li.classList.add('selected', 'active');
    btn.textContent = li.querySelector('span .text').textContent;
    btn.setAttribute('dataSelect', li.getAttribute('data-original-index'))
    select.classList.remove('open');
}
//Поиск по списку по введенным в инпут символам

export function filterList(inp) {
    let list = inp.parentElement.parentElement.querySelectorAll('ul li');
    let inp_value = inp.value;
    list.forEach(li => {
        li.querySelector('span .text').textContent.toLowerCase().includes(inp_value.toLowerCase()) ? li
            .classList.remove('hide') : li.classList.add('hide');
    })
}

//Открываем/закрываем селект и очищаем инпут в нем
function toggleSelectOption(btn) {
    if (!btn.classList.contains('selection-disabled')) {
        const select = btn.parentElement;
        select.classList.toggle('open');
        if (select.classList.contains('open')) {
            const inp = select.querySelector('.input-block-level.form-control');
            inp.value = '';
            filterList(inp);
            document.querySelectorAll('.btn-group.bootstrap-select.mb-15').forEach(el => {
                if (el != select) el.classList.remove('open')
            });
        }
    }
}
//
function selectElem(anchor, id) {
    selectedElement = document.querySelector('#product-select-' + anchor);
    par = selectedElement.querySelector('[data-original-index="' + id + '"]');
    if (par) {
        setActiveClass(par);
    }
}
/*
    document.addEventListener("DOMContentLoaded", function() {
        for (var key in default_) {
            console.log(default_)
            if (default_.hasOwnProperty(key)) {
                selectElem(key, default_[key])
            }
        }
    });
*/
function getSelectData(selectElement) {
    let dataType = selectElement.getAttribute("data-type");
    if (dataType < 4) {
        sendChangeSelectData(selectElement, dataType);
        setActiveClass(selectElement)
    } else {
        setActiveClass(selectElement)
    }
}
//
function sendChangeSelectData(selectElement, dataType) {
    let category = selectElement.getAttribute("data-original-index");
    let value;
    if(dataType == 1) {
        value = selectElement.parentElement.parentElement.querySelector('.product-select-brand').value;
    }
    console.log('parrent_category_id:'+category, 'type:'+dataType, 'value:'+value)


    //let selectedOption = selectElement.getAttribute('data-original-index');
    resetSelect(dataType)
    let token = document.head.querySelector('meta[name="csrf-token"]').content;
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "/get-select-data", true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.setRequestHeader("X-CSRF-TOKEN", token);
    let targetSelectId = selectElement.getAttribute('data-slave');
    document.querySelector(`.fancybox-inner #${targetSelectId}`).classList.remove("selection-disabled");

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            let targetSelectId = selectElement.getAttribute('data-slave');
            let targetSelect = document.getElementById(targetSelectId);
            targetSelect.classList.remove("selection-disabled");
            let targetUl = document.querySelector(`.fancybox-inner #select-${targetSelectId}`)
            let fragment = document.createDocumentFragment();
            JSON.parse(xhr.responseText).forEach(item => {
                fragment.appendChild(createListItem(item.id, (parseInt(dataType) + 1), item.title));
            });
            targetUl.appendChild(fragment);
            targetUl.classList.remove("selection-disabled");
        }
    };
    xhr.send(JSON.stringify({
        parrent_category_id: category,
        slug: document.querySelector('[data-type-btn="1"] span').textContent,
        value: value,
        type: dataType
    }));
}
//
function getModelPage(selectElement, dataType) {
    category_id = document.getElementById('product-select-category').querySelector('li.selected.active').getAttribute('data-original-index');
    brand_id = document.getElementById('product-select-brand').querySelector('li.selected.active').getAttribute('data-original-index');
    seria_id = document.getElementById('product-select-seria').querySelector('li.selected.active').getAttribute('data-original-index');
    sub_seria_id = document.getElementById('product-select-sub_seria').querySelector('li.selected.active').getAttribute('data-original-index');
    model_id = document.getElementById('product-select-model').querySelector('li.selected.active').getAttribute('data-original-index');
    token = document.head.querySelector('meta[name="csrf-token"]').content;
    xhr = new XMLHttpRequest();
    xhr.open("POST", "/get-select-data", true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.setRequestHeader("X-CSRF-TOKEN", token);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            responseObj = JSON.parse(xhr.response)[0];
            window.location.href = '/product/' + responseObj.categoty + '/' + responseObj.product_id + '.html';
            // window.location.href = xhr.responseText;
        }
    };
    xhr.send(JSON.stringify({
        value: model_id,
        type: '5',
        brand_id: brand_id,
        seria_id: seria_id,
        sub_seria_id: sub_seria_id,
        category_id: category_id
    }));
}
//
function resetSelect(target_) {
    let selectElements = document.querySelectorAll('.btn.dropdown-toggle.selectpicker.btn-default');
    selectElements.forEach(function (selectElement) {
        let targetNode = selectElement.getAttribute("data-type-btn")
        if (targetNode > target_) {
            if (targetNode > (parseInt(target_) + 1)) {
                selectElement.classList.add("selection-disabled");
            }
            setActiveClass(document.querySelector('[data-type="' + parseInt(targetNode) + '"]'))
        }
    });
}
//
function createListItem(dataOriginalIndex, dataType, textContent) {
    var li = document.createElement('li');
    var a = document.createElement('span');
    var spanText = document.createElement('span');
    var spanCheckMark = document.createElement('span');
    li.setAttribute('data-original-index', dataOriginalIndex);
    li.setAttribute('data-type', dataType);
   // data-slave="seria-{{ $brand_data['id'] }}"
    li.setAttribute('onclick', 'getSelectData(this);');
    a.setAttribute('tabindex', '0');
    a.setAttribute('class', '');
    a.setAttribute('data-normalized-text', '<span class="text">"' + textContent + '"</span>');
    spanText.setAttribute('class', 'text');
    spanText.textContent = textContent;
    spanCheckMark.setAttribute('class', 'glyphicon glyphicon-ok check-mark');
    a.appendChild(spanText);
    a.appendChild(spanCheckMark);
    li.appendChild(a);
    return li;
}

window.filterList = filterList;
window.toggleSelectOption = toggleSelectOption;
window.getSelectData = getSelectData;
window.getModelPage = getModelPage;